<template>
  <div v-if="langLoaded" class="pa-5">
    <v-overlay v-if="uploading">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
    <v-card
        class="mx-auto"
    >
      <v-card-title>
        <h2 v-t="'PatternsForm.PatternEditing'"></h2>
        <v-spacer></v-spacer>
        <v-btn color='secondary' v-t="'General.Return'" @click="$router.push({ name: 'Decos' })"></v-btn>
      </v-card-title>
      <v-form v-model="valid" ref="form">
        <v-container>
          <v-row>
            <v-col
            >
              <v-text-field
                  v-model="currentDeco.name"
                  :label="$t('CategoriesForm.CategoryName')"
                  required
                  outlined
                  clearable
              ></v-text-field>
              <v-row>
                <v-col>
                  <v-select
                      id="sectionsForm"
                      v-model="currentDeco.sectionList"
                      :items="this.sections"
                      item-text="name"
                      item-value="id"
                      :label="$t('General.Sections')"
                      multiple
                      outlined
                      clearable
                      required></v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col
            >
              <img v-if="currentDeco.svgPath !== ''" style="width: 30%"
                   :src="$axios.defaults.baseURL + '/downloadSvg?filename=' + currentDeco.svgPath"/>
            </v-col>
          </v-row>
          <v-card outlined>
            <v-card-title v-t="'General.ListTranslations'"></v-card-title>
            <v-card-text>
              <v-row class="align-center" v-for="lang in currentDeco.labelList" :key="lang.langValue">
                <v-col v-if="languageList && languageList.find((l) => { return l.value === lang.langValue })">
                  <v-chip> {{
                      languageList.find((l) => {
                        return l.value === lang.langValue
                      }).country
                    }}
                  </v-chip>
                </v-col>
                <v-col v-if="languageList && languageList.find((l) => { return l.value === lang.langValue })">
                  <v-text-field
                      v-model="lang.label"
                      required
                      outlined
                      clearable
                      :label="$t('PatternsForm.PatternNameIn') + languageList.find((l) => { return l.value === lang.langValue }).country"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-row>
            <v-col
            >
              <v-file-input @click:append="svgComputed ? $store.dispatch('downloadFile', currentDeco.svgPath) : null" :append-icon="svgComputed ? 'mdi-download-circle' : null" ref="fileInput" color="primary" class="ma-5" v-model="inputFileSvg"
                            @change='showPrefixSvg = false' outlined
                            accept="image/svg+xml" :clearable="false" :label="$t('PatternsForm.AddPattern')" :prefix="svgComputed"/>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <div class='float-right pa-3' style="display: flex; align-items: center">
        <v-switch
            style="margin-right: 30px"
            v-model="currentDeco.published"
            :label="$t('General.Publish')"
        ></v-switch>
        <v-btn :disabled="!valid" color='primary' @click='addOrModifyDeco'>{{
            $route.params.id.indexOf('new') === -1 ?
            $t('General.Edit') : $t('General.Add')
          }}
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'DecosForm',
  data () {
    return {
      valid: false,
      currentDeco: {
        id: '',
        name: '',
        labelList: [],
        svgPath: '',
        tagList: [],
        sectionList: [],
        published: false
      },
      decos: [],
      languageList: [],
      langLoaded: false,
      inputFileSvg: {},
      showPrefixSvg: true,
      sections: [],
      uploading: false
    }
  },
  computed: {
    svgComputed () {
      return this.showPrefixSvg ? this.getFileName(this.currentDeco.svgPath) : ''
    }
  },
  created () {
    this.getSections()
    if (this.$route.params.id.indexOf('new') === -1) {
      this.getCurrentDeco()
    }
    this.$store.dispatch('getAllSites').then(() => {
      this.sites = this.$store.getters['GET_SITES']()
      this.languageList = []
      this.sites.forEach((site) => {
        site.languageList.forEach((langToAdd) => {
          if (!this.languageList.find((lang) => {
            return lang.value === langToAdd.value
          })) {
            this.languageList.push(langToAdd)
          }
        })
      })
      this.languageList.forEach((lang) => {
        if (!this.currentDeco.labelList.find((langLabel) => {
          return langLabel.langValue === lang.value
        })) {
          this.currentDeco.labelList.push({ langValue: lang.value, label: '' })
        }
      })
      this.langLoaded = true
    })
  },
  methods: {
    getSections () {
      this.$store.dispatch('getAllSections').then(() => {
        this.sections = this.$store.getters['GET_SECTIONS']().filter((section) => {
          return section.idParent === null
        })
      })
    },
    getFileName (name) {
      if (!name) return ''
      name = name.split('_')
      name.shift()
      return name.join('')
    },
    addSvg () {
      if (this.inputFileSvg.name) {
        this.uploading = true
        var reader = new FileReader()

        reader.onload = () => {
          var data = new FormData()
          var blob = new Blob([reader.result])
          data.append('fileName', this.inputFileSvg.name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
          data.append('id', this.currentDeco.id)
          data.append('file', blob)
          this.$store.dispatch('uploadDecoSvg', data)
        }
        // read the file as text using the reader
        reader.readAsArrayBuffer(this.inputFileSvg)
      }
    },
    getCurrentDeco () {
      this.$store.dispatch('getDeco', this.$route.params.id).then(() => {
        this.currentDeco = this.$store.getters['GET_CURRENT_DECO']()
        if (this.currentDeco === null) {
          this.$emit('notify', {
            color: 'red',
            text: this.$t('PatternsForm.PatternNotFound')
          })
          this.$router.push({ name: 'Decos' })
        }
      })
    },
    addOrModifyDeco () {
      this.$refs.form.validate()
      if (this.valid) {
        if (this.currentDeco.id) {
          this.modifyDeco()
        } else {
          this.addDeco()
        }
      }
    },
    addDeco () {
      this.$store.dispatch('addDeco', this.currentDeco).then(() => {
        this.currentDeco = this.$store.getters['GET_CURRENT_DECO']()
        this.addSvg()
        setTimeout(() => {
          this.$router.push({ name: 'Decos' })
        }, 1000)
      }).catch((err) => {
        this.$emit('notify', {
          color: 'red',
          text: this.$t('Messages.' + err.response.data)
        })
      })
    },
    modifyDeco () {
      this.$store.dispatch('editDeco', this.currentDeco).then(() => {
        this.addSvg()
        setTimeout(() => {
          this.$router.push({ name: 'Decos' })
        }, 1000)
      }).catch((err) => {
        this.$emit('notify', {
          color: 'red',
          text: this.$t('Messages.' + err.response.data)
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
